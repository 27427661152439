// NavbarLinks.js

import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import Dropdown from 'react-bootstrap/Dropdown'
import {NavDropdown} from "react-bootstrap";

const NavItem = styled(Link)`
  text-decoration: none;
  color: #0d6efd;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: goldenrod;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: goldenrod;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
  }
`

const NavbarLinks = () => {
    return (
        <>
            <NavItem to="/contact/security">EL SIKKERHET</NavItem>
            <NavItem to="/">FEILMELDING</NavItem>
            <NavItem to="/contact/nett">NETT</NavItem>
            <NavItem href="https://elwin.sysco.no/pls/kundeweb_uvdal/webuser.login.login">MIN SIDE</NavItem>
            <NavItem to="/contact">BYGGESTRØM</NavItem>
            <NavItem to="/contact/basic">KONTAKT OSS</NavItem>
        </>
    )
}


export default NavbarLinks