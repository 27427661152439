import React from 'react'
import { Link } from 'gatsby'
import NavbarLinks from "./NavbarLinks"
import styled from 'styled-components'



const Navigation = styled.nav``

const Navbox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    padding-top: 10vh;
    background-color: #fff;
    transition: all 0.3s ease-in;
    top: 8vh;
    left: ${props => (props.open ? "-100%" : "0")};
  }
`

const Hamburger = styled.div`
  background-color: #111;
  width: 30px;
  height: 3px;
  transition: all .3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: #111;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
    props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
        {
          active: !this.state.active,
        },
        // after state has been updated,
        () => {
          // set the class in state for the navbar accordingly
          this.state.active
              ? this.setState({
                navBarActiveClass: 'is-active',
              })
              : this.setState({
                navBarActiveClass: '',
              })
        }
    )
  }

  render() {
    return (
        <nav
            className="navbar is-transparent"
            role="navigation"
            aria-label="main-navigation"
        >
          <div className="container">
            <div className="navbar-brand">
              <Link to="/" className="navbar-item" title="Logo">
                <img src="/img/logo_cut.png" alt="Kaldi" style={{ width: '88px' }} />
              </Link>
              {/* Hamburger menu */}
              <div
                  className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                  data-target="navMenu"
                  onClick={() => this.toggleHamburger()}
              >
                <span />
                <span />
                <span />
              </div>
            </div>
            <div
                id="navMenu"
                className={`navbar-menu ${this.state.navBarActiveClass}`}
            >
              <Navigation>
                    <Navbox>
                      <NavbarLinks />
                    </Navbox>
              </Navigation>
{/*              <div className="navbar-start has-text-centered">
                <Link className="navbar-item" to="/">
                  El Sikkerhet
                </Link>
                <Link className="navbar-item" to="/">
                  Feilmelding
                </Link>
                <Link className="navbar-item" href='/pdf/Tariffhefte-2020.pdf'>
                  Nett
                </Link>
                <Link className="navbar-item" href="https://elwin.sysco.no/pls/kundeweb_uvdal/webuser.login.login">
                  Min side
                </Link>
                <Link className="navbar-item" href="/about">
                  Kontakt oss
                </Link>
              </div>*/}
            </div>
          </div>
        </nav>
    )
  }
}

export default Navbar
